import React, {useEffect, useState} from "react";
import getApiClient from "../../Service/apiService";
import {AxiosResponse} from "axios";


function HandoutTable() {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchHandouts = async () => {
            const apiClient = await getApiClient();
            await apiClient.get("/handouts").then((response: AxiosResponse) => {
                console.log(response);
                setData(response.data.data);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });
        };

        fetchHandouts();

    }, []);

    return (
        <div>
            <div className={"my-5"}>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <table className={"w-full text-sm text-left rtl:text-right text-gray-500 white:text-gray-400"}>
                    <thead className={" text-gray-700 uppercase bg-gray-50 white:bg-gray-700 white:text-gray-400"}>
                    <tr data-href={"http://localhost:3000/handout"} className={"border-solid clickable-row "}>
                        <th>ID</th>
                        <th>Hash</th>
                        <th>Geaccepteerd</th>
                        <th>Items</th>
                    </tr>
                    </thead>
                    <tbody className={"bg-white border-b white:bg-gray-800 white:border-gray-700"}>
                    {data.map((handout: any,) => (
                        <tr className={"bg-white border-b white:bg-gray-800 white:border-gray-700 hover:bg-gray-50 white:hover:bg-gray-600 hover:cursor-pointer"}
                            key={handout.id}>
                            <td>{handout.id}</td>
                            <td>{handout.hash}</td>
                            <td>{handout.acceptTerms ? (<p className="text-green-600" >Geaccepteerd</p>) : (
                                <p className="text-red-600">Niet Geaccepteerd</p>)} </td>
                            <td> {handout.item.map((items: any) => (
                                    <td key={handout.item.id} >{items.Label}</td>
                            ))}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    )
}

export default HandoutTable;
