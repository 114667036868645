import HandoutTable from "../../Components/Tables/handoutTable";
import Navigation from "../../Components/navigation";
import React from "react";

function Handouts() {
    return (
        <>
            <Navigation></Navigation>
            <div className="container py-5 my-5 px-4 mx-auto">
                <HandoutTable></HandoutTable>
            </div>

        </>
    )
}

export default Handouts

