import AddLicenseForm from "../../Components/Forms/addLicenseForm";
import Navigation from "../../Components/navigation";
import React from "react";


function NewLicense() {
    return (
        <>
            <Navigation></Navigation>
            <AddLicenseForm></AddLicenseForm>

        </>
    )
}

export default NewLicense

