
import React from "react";

function Unauthorized() {
    return (
        <>
            <div className="container py-5 my-5 px-4 mx-auto text-center">
                <h1 className="text-xl">Geen toegang</h1>
                <p>Je hebt geen toegang tot deze pagina. Raadpleeg je beheerder</p>
                <br/>
                <p>of ga terug naar het dashboard</p>
             </div>

        </>
    )
}

export default Unauthorized

