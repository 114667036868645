import getApiClient from "../../Service/apiService";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import success from "../../Components/Validation/success";
import Success from "../../Components/Validation/success";
import Navigation from "../../Components/navigation";

function AcceptedHandout() {

    const [acceptHandoutTerms, setHandoutTerms] = useState(false);
    const {hash} = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');


    const closePopup = () => {
        // Close the popup
        setIsOpen(false);
    };

    function handleChange() {
        setHandoutTerms(true);
        console.log(acceptHandoutTerms);
        acceptHandout();
    }

    async function acceptHandout() {
        const apiClient = await getApiClient();
        await apiClient.post('/email/accept-terms/'+ hash, acceptHandoutTerms ).then((response) => {
            console.log(response);
            setMessage(response.data.message);
            window.location.href = 'https://mediatastisch-inventaris.ewag.nl/dashboard';
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <Navigation></Navigation>
            <div className="container py-5 my-5 px-4 mx-auto">
                <Success message={message} isOpen={isOpen} onClose={closePopup}></Success>
                <h1 className="text-clip">Accepteren voorwaarden</h1>
                <p>Klik hieronder op accepteren om u items te kunnen ontvangen</p>
                <button className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleChange}>Accepteren</button>
                <a href='https://mediatastisch-inventaris.ewag.nl/dashboard'>Weigeren</a>
            </div>

        </>
    )
}

export default AcceptedHandout

