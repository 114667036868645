import React from "react";
import LoginButton from "../../Components/Authentication/loginButton";
import Navigation from "../../Components/navigation";

function Login() {

    return (
        <>
            <div className="background-image">
                <div className="container py-5 my-10 px-4 mx-auto text-center">
                    <div className="grid grid-cols-3 gap-4">
                        <div className=" p-4"></div>
                        <div className="bg-gray-50 p-4 my-auto ">
                            <h1 className="text-xl font-bold">Inventaris Systeem</h1>
                            <hr className="py-4"/>
                            <p className="py-4">Login met je google account om gebruik te maken van het systeem</p>
                            <div className="relative flex justify-center">
                                <LoginButton></LoginButton>
                            </div>
                        </div>
                        <div className=" p-4"></div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Login

