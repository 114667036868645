import jwt_decode from "jwt-decode";

const AuthService = (function () {
    function storeUserRoles(roles: string[]): void {
        localStorage.setItem("roles", JSON.stringify(roles));
    }

    function getUserRole(): string | null {
        const token = getUserToken();
        if(token) {
            const decodedToken = jwt_decode(token);
            if(decodedToken) {
                // @ts-ignore
                const userRole = decodedToken.roles;
                return userRole ?? null;
            }
        }
        return null;
    }
    function storeUsername(username: string): void {
        localStorage.setItem("username", username);
    }

    function storeUserToken(token: string): void {
        localStorage.setItem("access_token", token);
    }
    function storeUserTokenExpiration(tokenExpires: number): void {
        localStorage.setItem("token_expires", tokenExpires.toString());
    }

    function getUserToken(): string | null {
        return localStorage.getItem("access_token");
    }
    function clearUserSession(): void {
        localStorage.clear();
    }
    function hasRole(role: string): boolean {
        const userRoles = JSON.parse(localStorage.getItem("roles") || "[]");
        return userRoles.includes(role);
    }
    return {
        getUserToken,
        getUserRole,
        storeUserRoles,
        storeUsername,
        storeUserToken,
        storeUserTokenExpiration,
        clearUserSession,
        hasRole,
    };
})();

export default AuthService;
