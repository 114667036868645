import getApiClient from "../../Service/apiService";
import React, {MouseEventHandler, useEffect, useRef, useState} from "react";
import {AxiosResponse} from "axios";

function LiceenseTable() {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [users, setUsers] = useState([]);

    const openModal = (object: any) => {
        setSelectedItem(object);
        setModalOpen(true);
    }

    const handleFileImport = (file: File) => {
        console.log(file);

    }


    const openEditModal = (object: any) => {
        setSelectedItem(object);
        setEditModalOpen(true);
        console.log(selectedItem);
    }


    const fetchUsers = async () => {
        const apiClient = await getApiClient();
        await apiClient.get("/users").then((response: AxiosResponse) => {
            setUsers(response.data.data);
        }).catch((error) => {
        });
    };


    useEffect(() => {

        const fetchLicenses = async () => {
            const apiClient = await getApiClient();
            await apiClient.get("/licenses").then((response: AxiosResponse) => {
                setData(response.data.data);
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
            });
        };

        fetchLicenses();
        fetchUsers();
    }, []);


    return (

        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <table className={"w-full text-sm text-left rtl:text-right text-gray-500 white:text-gray-400"}>
                    <thead className={" text-gray-700 uppercase bg-gray-50 white:bg-gray-700 white:text-gray-400"}>
                    <tr data-href={"http://localhost:3000/item"} className={"border-solid clickable-row "}>
                        <th>ID</th>
                        <th>Naam</th>
                        <th>Start tijd</th>
                        <th>Einde</th>
                        <th>Wordt gebruikt door?</th>
                        <th>Duratie</th>
                        <th>Actief</th>
                        <th>Prijs</th>
                        <th>Licentie sleutel</th>

                        {/* Add more <th>Type</th>columns as needed */}
                    </tr>
                    </thead>
                    <tbody className={"bg-white border-b white:bg-gray-800 white:border-gray-700"}>
                    {data.map((item: any,) => (
                        <tr className={"bg-white border-b white:bg-gray-800 white:border-gray-700 hover:bg-gray-50 white:hover:bg-gray-600 hover:cursor-pointer"}
                            key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.start_time}</td>
                            <td>{item.end_time}</td>
                            <td>{item.used_by}</td>
                            <td>{item.duration}</td>
                            <td>{item.active}</td>
                            <td>{item.price}</td>
                            <td>{item.license_key}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}


        </div>

    )
        ;
}

export default LiceenseTable