import {Formik, FormikHelpers, FormikValues} from "formik";
import getApiClient from "../../Service/apiService";
import Success from "../Validation/success";
import {useState} from "react";

function AddItemsForm() {

    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState<boolean>(false);


    const openPopup = () => {
        // Open the popup
        setIsOpen(true);
    }
    const closePopup = () => {
        // Close the popup
        setIsOpen(false);
    };

    const handleSubmit = async (values: FormikValues, {setStatus, resetForm}: FormikHelpers<FormikValues>) => {
        // Simulate an asynchronous operation (e.g., API request)
        try {
            await postItem(values)
            openPopup();
            resetForm();
        } catch (error) {
            setStatus({error: 'An error occurred while submitting the form.'});
        }
    };

    async function postItem(values: FormikValues) {
        const apiClient = await getApiClient();
        await apiClient.post('item/new', values).then((response: any) => {
            setMessage(response.data.message);
            openPopup();
            console.log(response);
        }).catch((error: any) => {
            console.log(error);
        });

    }

    return (

        <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Nieuw item toevoegen</h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                    Vul de gegevens in van het item dat je wilt toevoegen.
                </p>
            </div>

            <Success message={message} isOpen={isOpen} onClose={closePopup}></Success>

            <Formik<FormikValues>


                initialValues={{
                    item_label: '',
                    item_sort: '',
                    item_type: '',
                    item_in_use_by: '',
                    item_test_device: '',
                    item_purchase_date: '',
                    item_purchase_place: '',
                    item_invoice_number: '',
                    item_serial_number: '',
                    item_purchase_price_excl_tax: '',
                    item_description: '',
                }} onSubmit={handleSubmit}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) =>
                    <form onSubmit={handleSubmit} method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="first-name"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Label
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="item_label"
                                        onChange={handleChange}
                                        value={values.item_label}
                                        id="item_label"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_sort"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Soort item
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="item_sort"
                                        onChange={handleChange}
                                        value={values.item_sort}
                                        id="item_sort"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_type"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Type
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="item_type"
                                        onChange={handleChange}
                                        value={values.item_type}
                                        id="item_type"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_in_use_by"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    In gebruik door?
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="item_in_use_by"
                                        onChange={handleChange}
                                        value={values.item_in_use_by}
                                        id="item_in_use_by"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_test_device"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Testapparaat?
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="item_test_device"
                                        onChange={handleChange}
                                        value={values.item_test_device}
                                        id="item_test_device"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_purchase_date"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Aanschafdatum
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="datetime-local"
                                        name="item_purchase_date"
                                        onChange={handleChange}
                                        value={values.item_purchase_date}
                                        id="item_purchase_date"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_purchase_place"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Waar gekocht?
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="item_purchase_place"
                                        onChange={handleChange}
                                        value={values.item_purchase_place}
                                        id="item_purchase_place"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_invoice_number"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Factuur NR.
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="item_invoice_number"
                                        onChange={handleChange}
                                        value={values.item_invoice_number}
                                        id="item_invoice_number"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_serial_number"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Serienummer
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="item_serial_number"
                                        onChange={handleChange}
                                        value={values.item_serial_number}
                                        id="item_serial_number"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_purchase_price_excl_tax"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Aanschafbedrag excl. BTW
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="number"
                                        name="item_purchase_price_excl_tax"
                                        onChange={handleChange}
                                        value={values.item_purchase_price_excl_tax}
                                        id="item_purchase_price_excl_tax"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="item_description"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Opmerkingen
                                </label>
                                <div className="mt-2.5">
              <textarea
                  name="item_description"
                  onChange={handleChange}
                  value={values.item_description}
                  id="item_description"
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={''}
              />
                                </div>
                            </div>
                        </div>
                        <div className="mt-10">
                            <button
                                disabled={isSubmitting}
                                type="submit"
                                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Item toevoegen
                            </button>
                        </div>
                    </form>
                }
            </Formik>
        </div>
    );

}

export default AddItemsForm