import {XMarkIcon} from "@heroicons/react/24/outline";

interface ComponentsProps {
    message: string,
    isOpen: boolean,
    onClose: any,
}



const Success: React.FC<ComponentsProps> = ({message, isOpen, onClose}) => {


    if (message !== '' && isOpen)
        return (
            <div id={'popup'}
                 className="mx-auto max-w-3xl relative isolate flex items-center gap-x-6 overflow-hidden bg-green-400 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
                    <p className="text-sm leading-6 text-green-900">
                        {message}
                    </p>

                </div>
                <div className="flex flex-1 justify-end">
                    <button type="button" onClick={onClose} className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                        <span className="sr-only">Dismiss</span>
                        <XMarkIcon className="h-5 w-5 text-gray-900" aria-hidden="true"/>
                    </button>
                </div>
            </div>
        )
    else {
    }
    return (
        <div></div>
    )

}

export default Success;
