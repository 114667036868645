import {Formik, FormikHelpers, FormikValues} from "formik";
import getApiClient from "../../Service/apiService";
import Success from "../Validation/success";
import {useState} from "react";


function UserInvintationForm() {

    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState<boolean>(false);


    const openPopup = () => {
        // Open the popup
        setIsOpen(true);
    }
    const closePopup = () => {
        // Close the popup
        setIsOpen(false);
    };


    const handleSubmit = async (values: FormikValues, {setStatus, resetForm}: FormikHelpers<FormikValues>) => {
        // Simulate an asynchronous operation (e.g., API request)
        try {
            await sendInvintation(values)
            openPopup();
            resetForm();
        } catch (error) {
            setStatus({error: 'An error occurred while submitting the form.'});
        }
    };

    async function sendInvintation(values: FormikValues) {
        const apiClient = await getApiClient();
        await apiClient.post('user/invite', values).then((response: any) => {
            setMessage(response.data.message);
            console.log(response);
        }).catch((error: any) => {
            console.log(error);
        });

    }

    return (

        <div>
            <Success message={message} isOpen={isOpen} onClose={closePopup}></Success>
            <Formik<FormikValues>
                initialValues={{
                    user_email: '',
                }} onSubmit={handleSubmit}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) =>
                    <form onSubmit={handleSubmit} method="POST" className="">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="first-name"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    E-mail gebruiker
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="user_email"
                                        onChange={handleChange}
                                        value={values.user_email}
                                        id="user_email"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-10">
                            <button
                                disabled={isSubmitting}
                                type="submit"
                                className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Gebruiker uitnodigen
                            </button>
                        </div>
                    </form>
                }
            </Formik>
        </div>


    );

}


export default UserInvintationForm