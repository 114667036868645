import React, {useEffect, useState} from "react";
import getApiClient from "../../Service/apiService";
import {AxiosResponse} from "axios";
import ErrorBadge from "../Badges/errorBadge";
import WarningBadge from "../Badges/warningBadge";
import SuccessBadge from "../Badges/successBadge";

function CategoryTable() {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const setStatus = (amount : any) => {
        if(amount == 0){
            return <><ErrorBadge text={"Leeg"}></ErrorBadge></>
        }
        if(amount > 0 && amount < 10){
            return <><WarningBadge text={"Bijna leeg"}></WarningBadge></>
        }
        if(amount >= 10){
            return <><SuccessBadge text={"Vol"}></SuccessBadge></>
        }

    }


    useEffect(() => {

            const fetchCategories = async () => {
                const apiClient = await getApiClient();
                await apiClient.get("/item/categories").then((response: AxiosResponse) => {
                    console.log(response);
                    setData(response.data.data);
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
            };

            fetchCategories();
    }, []);

    return (
        <>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <table className={"w-full text-sm text-left rtl:text-right text-gray-500 white:text-gray-400"}>
                    <thead className={" text-gray-700 uppercase bg-gray-50 white:bg-gray-700 white:text-gray-400"}>
                    <tr data-href={"http://localhost:3000/item"} className={"border-solid clickable-row "}>
                        <th>Soort</th>
                        <th>Hoeveelheid</th>
                        <th>Status</th>
                        {/* Add more <th>Type</th>columns as needed */}
                    </tr>
                    </thead>
                    <tbody className={"bg-white border-b white:bg-gray-800 white:border-gray-700"}>
                    {data.map((item: any,) => (
                        <tr className={"bg-white border-b white:bg-gray-800 white:border-gray-700 hover:bg-gray-50 white:hover:bg-gray-600 hover:cursor-pointer"}
                            key={item.id}>
                            <td>{item.Sort}</td>
                            <td>{item.CountBySort}</td>
                            <td>{setStatus(item.CountBySort)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </>
    )


}

export default CategoryTable;