import AddItemsForm from "../../Components/Forms/addItemsForm";
import Navigation from "../../Components/navigation";
import React from "react";

function newItem() {
    return (
        <>
            <Navigation></Navigation>
        <AddItemsForm></AddItemsForm>
        </>
    )
}

export default newItem

