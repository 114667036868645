import React, {Dispatch, Fragment, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline'
import {AxiosResponse} from "axios";
import getApiClient from "../../Service/apiService";
import Success from "../Validation/success";


interface YourComponentProps {
    isModalOpen: boolean;
    setModalOpen: Dispatch<boolean>;
    cancelButtonRef?: any;
    item: any;
}


const ItemModal: React.FC<YourComponentProps> = ({ isModalOpen, setModalOpen ,cancelButtonRef , item}) => {


    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onClose = () => {
        window.location.reload();
        setModalOpen(false);
    }
    const openPopup = () => {
        // Open the popup
        setIsOpen(true);
    }
    const closePopup = () => {
        // Close the popup
        setIsOpen(false);
    };



    const removeItem = async (item: any) => {
        const apiClient = await getApiClient();
        await apiClient.post("/item/delete", {
            item_id: item.id
        }).then((response: AxiosResponse) => {
            setMessage(response.data.message);
            openPopup();
            console.log(response);
        }).catch((error) => {

            console.log(error);
        });
        setModalOpen(false);
    }



    return (

        <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div
                        className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div
                                            className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600"
                                                                     aria-hidden="true"/>
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3"
                                                          className="text-base font-semibold leading-6 text-gray-900">
                                                Item verwijderen
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <Success message={message} isOpen={isOpen} onClose={closePopup}></Success>
                                                <p className="text-sm text-gray-500">
                                                   Weet je zeker dat je het item wilt verwijderen?

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                        onClick={() => removeItem(item)}
                                    >
                                        Verwijderen
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => onClose()}
                                        ref={cancelButtonRef}
                                    >
                                        Annuleren
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ItemModal;

