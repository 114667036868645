import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {env} from "@headlessui/react/dist/utils/env";




const apiClient = axios.create({
    baseURL: "https://api-inventaris.ewag.nl/api/",
});

const getApiClient = async () => {
    return apiClient;
}

export default getApiClient;

apiClient.interceptors.request.use((config: any) => {
    const token = localStorage.getItem("access_token");
       config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config
});

apiClient.interceptors.response.use((response: AxiosResponse) => {
        console.log(
            `%c ${response.status} - ${getUrl(response.config)}:`,
            response
        );
        return response;
    },
    (error) => {
        console.log(error.response.status);
        if (error.response.status === 401) {
            console.log('401 error rejected');
        }
        return Promise.reject(error);
    }
);


function getUrl(config: any) {
    if (config.baseURL) {
        return config.url.replace(config.baseURL, "");
    }
    return config.url;
}
