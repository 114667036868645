import React, {ReactNode} from 'react';
import {Route, Navigate, RouteProps, Routes} from 'react-router-dom';
import authenticationService from "../../Service/authenticationService";


interface ProtectedRouteProps {
    isAuthenticated: boolean;
    requiredRoles: string[];
    children: ReactNode
    path: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({isAuthenticated, requiredRoles, children}) => {

     isAuthenticated = authenticationService.getUserToken() != null && authenticationService.getUserRole() == requiredRoles[0];
     console.log(authenticationService.getUserRole() + " " + requiredRoles[0]);

    if (isAuthenticated) {
        return <>{children}</>;
    } else {
        return <Navigate to="/unauthorized"/>;
    }


};


export default ProtectedRoute;