import getApiClient from "../../Service/apiService";
import {useEffect, useState} from "react";
import {AxiosResponse} from "axios";
import RemoveUserModal from "../Modals/removeUserModal";
import UserModal from "../Modals/userModal";


function DataTable() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isUserModalOpen, setUserModalOpen] = useState<boolean>(false);
    const [user, setUser] = useState<any>([]);

    const openModal = (user: any) => {
        setUser(user);
        setModalOpen(true);
    }

    const openUserModal = (user: any) => {
        setUser(user);
        console.log(user);
        setUserModalOpen(true);

    }

    useEffect(() => {
        const fetchData = async () => {
            const apiClient = await getApiClient();
            await apiClient.get("/users").then((response: AxiosResponse) => {
                console.log(response);
                setData(response.data.data);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });
        };
        fetchData().then(r => console.log("done"));
    }, []);

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <table className={"w-full text-sm text-left rtl:text-right text-gray-500 white:text-gray-400"}>
                    <thead className={" text-gray-700 uppercase bg-gray-50 white:bg-gray-700 white:text-gray-400"}>
                    <tr data-href={"http://localhost:3000/item"} className={"border-solid clickable-row "}>
                        <th>ID</th>
                        <th>E-mail</th>
                        <th>Acties</th>
                        {/* Add more <th>Type</th>columns as needed */}
                    </tr>
                    </thead>
                    <tbody className={"bg-white border-b white:bg-gray-800 white:border-gray-700"}>
                    {data.map((user: any) => (

                        <tr data-href=''
                            className={"bg-white border-b white:bg-gray-800 white:border-gray-700 hover:bg-gray-50 white:hover:bg-gray-600 hover:cursor-pointer"}
                            key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.email}</td>
                            <td>
                                <button onClick={() => openUserModal(user)}
                                        className={"bg-blue-500 text-white py-1 px-2 rounded"}>Bekijken
                                </button>
                                <button onClick={openModal}
                                        className={"bg-red-500 text-white py-1 px-2 rounded"}>Verwijderen
                                </button>
                            </td>
                        </tr>

                    ))}
                    </tbody>
                </table>

            )}
            <RemoveUserModal isModalOpen={isModalOpen} setModalOpen={setModalOpen}></RemoveUserModal>
            <UserModal isModalOpen={isUserModalOpen} setModalOpen={openUserModal} user={user}></UserModal>
        </div>
    );
}

export default DataTable