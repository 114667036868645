

import LiceenseTable from "../../Components/Tables/licenseTable";
import Navigation from "../../Components/navigation";
import React from "react";


function Licenses() {
    return (
        <>
            <Navigation></Navigation>
            <div className="container py-5 my-5 px-4 mx-auto">
            <LiceenseTable></LiceenseTable>
            </div>

        </>
    )
}

export default Licenses

