import getApiClient from "../../Service/apiService";
import React, {MouseEventHandler, useEffect, useRef, useState} from "react";
import {AxiosResponse} from "axios";
import RemoveModal from "../Modals/removeModal";
import ItemSelect from "../Select/userSelect";
import UserSelect from "../Select/userSelect";
import ButtonSave from "../Buttons/buttonSave";
import ItemEditModal from "../Modals/itemEditModal";
import FileImportComponent from "../Import/fileImport";
import InventoryFilter from "../Filters/Inventoryfilter";

function DataTable() {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [users, setUsers] = useState([]);

    const openModal = (object: any) => {
        setSelectedItem(object);
        setModalOpen(true);
    }

    const handleFileImport = (file: File) => {
        console.log(file);

    }



    const openEditModal = (object: any) => {
        setSelectedItem(object);
        setEditModalOpen(true);
        console.log(selectedItem);
    }


    const fetchUsers = async () => {
        const apiClient = await getApiClient();
        await apiClient.get("/users").then((response: AxiosResponse) => {
            setUsers(response.data.data);
        }).catch((error) => {
        });
    };


    useEffect(() => {

        const fetchItems = async () => {
            const apiClient = await getApiClient();
            await apiClient.get("/items").then((response: AxiosResponse) => {
                setData(response.data.data);
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
            });
        };

        fetchItems();
        fetchUsers();
    }, []);


    return (

        <div>

            <div className={"my-5"}>
               <FileImportComponent></FileImportComponent>
            </div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className={"max-h-[700px] h-[700px] overflow-auto"}>
                <table className={"w-full text-sm text-left rtl:text-right text-gray-500 white:text-gray-400"}>
                    <thead className={" text-gray-700 uppercase bg-gray-50 white:bg-gray-700 white:text-gray-400"}>
                    <tr data-href={"http://localhost:3000/item"} className={"border-solid clickable-row "}>
                        <th>ID</th>
                        <th>Label</th>
                        <th>Soort</th>
                        <th>Type</th>
                        <th>Test apparaat</th>
                        <th>Beschrijving</th>
                        <th>Plaats waar het gekocht is</th>
                        <th>Factuur nummer</th>
                        <th>Serie nummer</th>
                        <th>Prijs excl. BTW</th>
                        <th>Gebruiker</th>
                        <th>Acties</th>

                        {/* Add more <th>Type</th>columns as needed */}
                    </tr>
                    </thead>
                    <tbody className={"bg-white border-b white:bg-gray-800 white:border-gray-700"}>
                    {data.map((item: any,) => (
                        <tr className={"bg-white border-b white:bg-gray-800 white:border-gray-700 hover:bg-gray-50 white:hover:bg-gray-600 hover:cursor-pointer"}
                            key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.Label}</td>
                            <td>{item.Sort}</td>
                            <td>{item.Type}</td>
                            <td>{item.testDevice}</td>
                            <td>{item.Description}</td>
                            <td>{item.purchasePlace}</td>
                            <td>{item.invoiceNumber}</td>
                            <td>{item.serialNumber}</td>
                            <td>{item.purchasePriceExclTax}</td>
                            {item.User ? (
                                <>
                                    <td>{item.User.email}    </td>
                                </>) : (<td></td>)}

                            <td>
                                <svg onClick={() => openModal(item)} xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                     className="w-5 h-5 inline-block">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                                </svg>

                                <svg onClick={() => openEditModal(item)} xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                     className="w-5 h-5 inline-block">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"/>
                                </svg>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </div>
            )}
            <ItemEditModal isModalOpen={isEditModalOpen} item={selectedItem} users={users}
                           setModalOpen={setEditModalOpen}></ItemEditModal>
            <RemoveModal isModalOpen={isModalOpen} item={selectedItem} setModalOpen={setModalOpen}></RemoveModal>
        </div>

    );
}

export default DataTable