import Navigation from "../../Components/navigation";
import React from "react";

function Dashboard() {
    return (
        <>
                <Navigation></Navigation>
            <div className="container py-5 my-5 px-4 mx-auto">
                <h1 className="text-xl">Dashboard</h1>
            </div>

        </>
    )
}

export default Dashboard

