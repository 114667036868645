import React, {useState} from 'react';
import getApiClient from "../../Service/apiService";
import {AxiosResponse} from "axios";
import Success from "../Validation/success";


const FileImportComponent = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openPopup = () => {
        // Open the popup
        setIsOpen(true);
    }
    const closePopup = () => {
        // Close the popup
        setIsOpen(false);
    };
    const handleFileChange = (event: any) => {
        console.log(selectedFile);
        console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]);

    };

    const handleUpload = async () => {

        const formData = new FormData();

        if (selectedFile) {
            formData.append("importItem", selectedFile);
        }

        try {
            const apiClient = await getApiClient();
            await apiClient.post("/items/import", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((response: AxiosResponse) => {
                console.log(response.data.data);
                openPopup();
            }).catch((error) => {
                console.log(error);
            });
        } catch (e) {
            console.log(e);
        }

    }


    return (
        <div>
            <Success message={message} isOpen={isOpen} onClose={closePopup}></Success>
            <input type="file" onChange={handleFileChange}/>
            <button
                className={"mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"}
                onClick={handleUpload} disabled={!selectedFile}>
                Upload
            </button>
        </div>
    );
};

export default FileImportComponent;