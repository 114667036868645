import {Formik, FormikHelpers, FormikValues} from "formik";
import getApiClient from "../../Service/apiService";
import Success from "../Validation/success";
import {useState} from "react";

function AddLicenseForm() {

    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openPopup = () => {
        // Open the popup
        setIsOpen(true);
    }
    const closePopup = () => {
        // Close the popup
        setIsOpen(false);
    };
    const handleSubmit = async (values: FormikValues, {setStatus, resetForm}: FormikHelpers<FormikValues>) => {
        // Simulate an asynchronous operation (e.g., API request)
        try {
            await postLicense(values)
            openPopup();
            resetForm();
        } catch (error) {
            setStatus({error: 'An error occurred while submitting the form.'});
        }
    };
    async function postLicense(values: FormikValues) {
        const apiClient = await getApiClient();
        await apiClient.post('license/new', values).then((response: any) => {
            setMessage(response.data.message);
            console.log(response);
        }).catch((error: any) => {
            console.log(error);
        });

    }

    return (

        <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Nieuwe licentie
                    toevoegen</h2>
                <p className="mt-2 text-lg leading-8 text-gray-600">
                    Vul de gegevens in van de licentie dat je wilt toevoegen.
                </p>
            </div>


            <Success message={message} isOpen={isOpen} onClose={closePopup}></Success>
            <Formik<FormikValues>
                initialValues={{
                    license_name: '',
                    license_key: '',
                    license_duration: '',
                    license_price: '',
                    license_active: '',
                    license_start_time: '',
                    license_end_time: '',
                    license_used_by: '',
                }} onSubmit={handleSubmit}>
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) =>
                    <form onSubmit={handleSubmit} method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                            <div>
                                <label htmlFor="first-name"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Licentie naam
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="license_name"
                                        onChange={handleChange}
                                        value={values.license_name}
                                        id="license_name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_sort"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Licentie key
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="license_key"
                                        onChange={handleChange}
                                        value={values.license_key}
                                        id="license_key"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_type"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Duratie
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="license_duration"
                                        onChange={handleChange}
                                        value={values.license_duration}
                                        id="license_duration"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_in_use_by"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Prijs
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="license_price"
                                        onChange={handleChange}
                                        value={values.license_price}
                                        id="license_price"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>             <div>
                            <label htmlFor="item_purchase_date"
                                   className="block text-sm font-semibold leading-6 text-gray-900">
                                Wanneer verloopt het?
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="datetime-local"
                                    name="license_end_time"
                                    onChange={handleChange}
                                    value={values.license_end_time}
                                    id="license_end_time"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                            <div>
                                <label htmlFor="item_purchase_date"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Wanneer is het aangeschaft?
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="datetime-local"
                                        name="license_start_time"
                                        onChange={handleChange}
                                        value={values.license_start_time}
                                        id="license_start_time"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_test_device"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Is het actief?
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="license_active"
                                        onChange={handleChange}
                                        value={values.license_active}
                                        id="license_active"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="item_invoice_number"
                                       className="block text-sm font-semibold leading-6 text-gray-900">
                                    Welke klant gebruikt het?
                                </label>
                                <div className="mt-2.5">
                                    <input
                                        type="text"
                                        name="license_used_by"
                                        onChange={handleChange}
                                        value={values.license_used_by}
                                        id="license_used_by"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-10">
                        <button
                            disabled={isSubmitting}
                            type="submit"
                            className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Licentie toevoegen
                        </button>
                        </div>
                    </form>
                }
            </Formik>
        </div>
    );

}


export default AddLicenseForm