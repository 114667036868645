import React, {Dispatch} from "react";


interface YourComponentProps {
    text: string;
}

const  SuccessBadge: React.FC<YourComponentProps> = ({text}) => {
    return (
        <span
            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
        {text}
      </span>
    )
}

export default SuccessBadge