import DataTable from "../../Components/Tables/itemTable";
import Navigation from "../../Components/navigation";
import CategoryTable from "../../Components/Tables/categoryTable";
import React from "react";



function Items() {
    return (
        <>
            <Navigation></Navigation>
            <div className="container py-5  my-5 px-4 mx-auto">
                <div className={"my-5 px-2 py-2 border-2 border-slate-200 rounded-md bg-slate-100"}>
                    <DataTable></DataTable>
                </div>
                <div className={"my-5 px-2 py-2 border-2 border-slate-200 rounded-md bg-slate-100"}>
                    <CategoryTable></CategoryTable>
                </div>

            </div>

        </>
    )
}

export default Items

