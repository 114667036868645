import React, {ReactNode} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./Screens/Authentication/login";
import LoginButton from "./Components/Authentication/loginButton";
import ProtectedRoute from "./Components/Routes/protectedRoute";
import Items from "./Screens/Items/Items";
import NewItem from "./Screens/Items/new-item";
import Users from "./Screens/Users/Users";
import Licenses from "./Screens/Licenses/Licenses";
import NewLicense from "./Screens/Licenses/new-license";
import NewPackage from "./Screens/Items/new-package";
import Dashboard from "./Screens/Dashboard/dashboard";
import AcceptHandout from "./Screens/Handout/acceptHandout";
import Handout from "./Screens/Handout/Handouts";
import jwt_decode from "jwt-decode";
import Unauthorized from "./Screens/Authentication/unauthorized";


function App() {
        return (
            <Router>
                <Routes>
                    <Route>
                        <Route path="/" element={<Login/>}></Route>
                        <Route path="/login" element={<LoginButton/>}></Route>
                        <Route path="/items" element={
                            <ProtectedRoute requiredRoles={['ROLE_USER']}   path={"/"} isAuthenticated>
                                <Items/>
                            </ProtectedRoute>
                        }></Route>
                        <Route path="/items/new" element={
                            <ProtectedRoute requiredRoles={['ROLE_USER']}   path={"/"} isAuthenticated>
                                <NewItem/>
                            </ProtectedRoute>
                        }></Route>
                        <Route path="/Gebruikers" element={
                            <ProtectedRoute requiredRoles={['ROLE_USER']}   path={"/"} isAuthenticated>
                                <Users/>
                            </ProtectedRoute>
                        }></Route>
                        <Route path="/licenses" element={
                            <ProtectedRoute requiredRoles={['ROLE_USER']}   path={"/"} isAuthenticated>
                                <Licenses/>
                            </ProtectedRoute>
                        }></Route>
                        <Route path="/license/new" element={
                            <ProtectedRoute requiredRoles={['ROLE_USER']}   path={"/"} isAuthenticated>
                                <NewLicense/>
                            </ProtectedRoute>
                        }></Route>
                        <Route path="/package/new" element={
                            <ProtectedRoute requiredRoles={['ROLE_USER']}   path={"/"} isAuthenticated>
                                <NewPackage/>
                            </ProtectedRoute>
                        }></Route>
                        <Route path="/dashboard" element={
                            <ProtectedRoute requiredRoles={['ROLE_USER']}   path={"/"} isAuthenticated>
                                <Dashboard/>
                            </ProtectedRoute>
                        }></Route>
                        <Route path="/email/accept-terms/:hash" element={
                            <ProtectedRoute requiredRoles={['ROLE_USER']}   path={"/"} isAuthenticated>
                                <AcceptHandout/>
                            </ProtectedRoute>
                        }></Route>
                        <Route path="/handouts" element={
                            <ProtectedRoute requiredRoles={['ROLE_USER']}   path={"/"} isAuthenticated>
                                <Handout/>
                            </ProtectedRoute>
                        }></Route>
                        <Route path="/unauthorized" element={<Unauthorized/>}/>
                    </Route>
                </Routes>
            </Router>
        )
}

export default App;
