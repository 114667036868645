import UserTable from "../../Components/Tables/userTable";
import React from "react";
import UserInvintationForm from "../../Components/Forms/userInvintationForm";
import Navigation from "../../Components/navigation";

function Users() {
    return (
        <>
            <Navigation></Navigation>
            <div className="container mx-auto py-5 my-5 px-4">
                <div className="grid grid-cols-2 gap-4">
                    <div className="p-4">
                        <h1>Gebruikers</h1>
                        <div className={"my-5 px-2 py-2 border-2 border-slate-200 rounded-md bg-slate-100"}>
                        <UserTable></UserTable>
                        </div>
                    </div>
                    <div className="p-4">
                        <h1>Acties</h1>
                            <div className={"my-5 px-2 py-2 border-2 border-slate-200 rounded-md bg-slate-100"}>
                            <UserInvintationForm></UserInvintationForm>
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users

