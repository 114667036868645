import ItemSelect from "../../Components/Select/itemSelect";
import React, {useEffect, useState} from "react";
import getApiClient from "../../Service/apiService";
import {AxiosResponse} from "axios/index";
import UserSelect from "../../Components/Select/userSelect";
import Navigation from "../../Components/navigation";


function NewPackage() {

    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [users, setUsers] = useState([]);
    const handleUserSelect = (user: any) => {
        setSelectedUser(user);
    };
    const handleSelectChange = (selectedOption: never) => {

        if (!selectedItems.includes(selectedOption)) {
            setSelectedItems([...selectedItems, selectedOption]);
        }
    };

    const handleRemoveItem = (itemToRemove: any) => {
        const updatedItems = selectedItems.filter(item => item !== itemToRemove);
        setSelectedItems(updatedItems);
    };

    const handleSave = () => {
        assignItemsToUser(selectedUser, selectedItems);
    }


    const assignItemsToUser = async (user: any, items: any) => {
        const apiClient = await getApiClient();
        await apiClient.post("/items/assign", {
            user_id: user.id,
            items: items
        }).then((response: AxiosResponse) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
    }


    useEffect(() => {

        const fetchUsers = async () => {
            const apiClient = await getApiClient();
            await apiClient.get("/users").then((response: AxiosResponse) => {
                setUsers(response.data.data);
            }).catch((error) => {
            });
        };

        const fetchItems = async () => {
            const apiClient = await getApiClient();
            await apiClient.get("/items/no-user").then((response: AxiosResponse) => {
                setData(response.data.data);
            }).catch((error) => {
                console.log(error);
            });
        };

        fetchItems();
        fetchUsers();
    }, []);


    return (
        <>
            <Navigation></Navigation>
            <div className="container py-5 my-5 px-4 mx-auto">
                <div className="grid grid-cols-2 gap-4">
                    <div className="p-4">
                        <h1 className="text-2xl">Nieuw pakket</h1>
                        <p className="text-gray-500">Selecteer een gebruiker en voeg items toe</p>
                        <hr className="my-4"/>
                        <h2 className="text-xl">Gebruiker</h2>
                        <UserSelect selectedUser={handleUserSelect} data={users}></UserSelect>

                        <h2 className="text-xl">Items</h2>
                        <ItemSelect data={data} selectedItem={selectedItem}
                                    onSelectChange={handleSelectChange}></ItemSelect>
                    </div>
                    <div className="p-4">
                        <h1 className="text-2xl">De volgende items zijn toegevoegd aan het pakket</h1>
                        <p className="text-gray-500">Hieronder zie je alle items die in het pakket zitten</p>
                        <hr className="my-4"/>
                        <div className="border-gray-500 border-2 border-solid rounded py-2 px-2 ">
                                                          <ul>
                                {selectedItems.map((item: any, index) => (
                                    <li key={index} className="block w-full relative">
                                        <span className="py-2 px-2">{item.Label}</span> <span
                                        className="py-2 px-2">{item.Sort}</span>

                                        <button className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" onClick={() => handleRemoveItem(item)}>Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                    onClick={handleSave}>
                    Pakket versturen
                </button>
            </div>
        </>
    )
}

export default NewPackage

