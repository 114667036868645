import {GoogleLogin, useGoogleLogin} from "@react-oauth/google";
import React from "react";
import axios from "axios";
import authenticationService from "../../Service/authenticationService";


function LoginButton() {
    const login = useGoogleLogin({
        onSuccess: (codeResponse: any) => axios.post('https://api-inventaris.ewag.nl/api/login', codeResponse).then((result) => {
            localStorage.setItem("access_token", result.data.access_token);
            console.log(result);
            window.location.href = "https://mediatastisch-inventaris.ewag.nl/dashboard";
        }).catch((error) => {
            console.log(error);
        }),
        flow: 'auth-code',
    });

    const errorMessage = (error: void) => {
        console.log(error);
    };

    function loggedIn() {
        return !!authenticationService.getUserToken();
    }

    function logout() {
        authenticationService.clearUserSession();
        if(!loggedIn()){
            window.location.href = "/";
        }
    }

    if (loggedIn()) {
        return (<button onClick={logout}>Logout</button>);
    } else {
        return ( <GoogleLogin ux_mode={"popup"}  onSuccess={login} onError={errorMessage}/>);
    }


}

export default LoginButton

